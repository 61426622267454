import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  ACCOUNT_ADD_COMPANY_USER_SUCCESS,
  REFRESH_AUTH_TOKEN,
  REFRESH_AUTH_TOKEN_FAILURE,
  ACCOUNT_RESET_PASSWORD_STATUS,
  ACCOUNT_RESET_PASSWORD_STATUS_FAILURE,
  CONFIRM_NEW_PASSWORD_STATUS,
  CONFIRM_NEW_PASSWORD_STATUS_FAILURE,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILURE,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_ROLE_PERMS_SUCCESS,
  GET_ROLE_PERMS_FAILURE,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  NEW_PASSWORD_CONFIRMED_STATUS,
  NEW_PASSWORD_CONFIRMED_STATUS_FAILURE,
  SAVE_PORTAL_SETTINGS_SUCCESS,
  SAVE_PORTAL_SETTINGS_FAILURE,
  GET_PORTAL_SETTINGS_SUCCESS,
  GET_PORTAL_SETTINGS_FAILURE,
  GET_PORTAL_SETTINGS_STATUS_SUCCESS,
  TOKEN_REVOCATION_SUCCESS,
  TOKEN_REVOCATION_FAILURE,
  DECODE_TOKEN,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
} from "@/constants/actionTypes";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "@/reducers";
import axios from "axios";
import asyncLocalStorage from "@/utils/asyncLocalStorage";
import * as AccountAPI from "@/api/account.api";
import {
  UploadFileType,
  RolePermsType,
  UserListType,
  RoleType,
  UserType,
  PortalSettingsDataType,
  UserInfoType,
} from "app/types";
import jwtDecode from "jwt-decode";
import { message } from "antd";

export type AccountActionsTypes =
  | LoginRequestType
  | LoginSuccessType
  | LoginFailureType
  | LogoutType
  | DecodeTokenType
  | GetCompanyNameType
  | RefreshTokenSuccessType
  | RefreshTokenFailureType
  | TokenRevocationSuccessType
  | TokenRevocationFailureType
  | FetchUserInfoSuccessType
  | FetchUserInfoFailureType
  | AccountResetPasswordType
  | AccountResetPasswordFailureType
  | ConfirmNewPasswordType
  | ConfirmNewPasswordFailureType
  | GetUsersListSuccessType
  | GetUsersListFailureType
  | UpdateUserSuccessType
  | UpdateUserFailureType
  | DeleteUserSuccessType
  | DeleteUserFailureType
  | CreateUserSuccessType
  | CreateUserFailureType
  | GetRolePermsSuccessType
  | GetRolePermsFailureType
  | GetRolesSuccessType
  | GetRolesFailureType
  | CreateRoleSuccessType
  | CreateRoleFailureType
  | DeleteRoleSuccessType
  | DeleteRoleFailureType
  | GetRoleInfoFailureType
  | GetRoleInfoSuccessType
  | UpdateRoleSuccessType
  | UpdateRoleFailureType
  | NewPasswordConfirmedStatusType
  | NewPasswordConfirmedStatusFailureType
  | SavePortalSettingsSuccessType
  | SavePortalSettingsFailureType
  | GetPortalSettingsSuccessType
  | GetPortalSettingsFailureType
  | GetPortalSettingsStatusType;

// Логика расшифровки токена авторизованного пользователя
type DecodeTokenType = {
  type: typeof DECODE_TOKEN;
  payload: {
    decodedToken: any;
  };
};

export const decodeToken = (token: any): DecodeTokenType => {
  const decodedToken: any = jwtDecode(token);

  return {
    type: DECODE_TOKEN,
    payload: {
      decodedToken
    }
  };
};

// Авторизация
type LoginRequestType = {
  type: typeof LOGIN_REQUEST;
};

export const loginRequest = (): LoginRequestType => {
  return {
    type: LOGIN_REQUEST,
  };
};

type LoginSuccessType = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const loginSuccess = (access_token: string, refresh_token: string): LoginSuccessType => ({
  type: LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type LoginFailureType = {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: string;
  };
};

export const loginFailure = (error?: string): LoginFailureType => ({
  type: LOGIN_FAILURE,
  payload: {
    error,
  },
});

export const login = (
  username: string,
  password: string,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  dispatch(loginRequest());

  try {
    const response = await AccountAPI.login(username, password);

    asyncLocalStorage
      .setItem("authtoken", JSON.stringify(response.data.access_token))
      .then(() => {
        dispatch(loginSuccess(response.data.access_token, response.data.refresh_token));
        axios.defaults.headers.common.Authorization = response.data.access_token;
      });

    localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));

  } catch (error: any) {
    const errorDescription: string = error?.response?.data?.error_description;
    let errorMessage: string;

    if (errorDescription === "Invalid user credentials") {
      errorMessage = "Неверные учетные данные пользователя";
    } else if (errorDescription === "Account is not fully set up") {
      message.warning("Учетная запись ожидает подтверждения администратором", 4)
    } else {
      errorMessage = errorDescription;
    }

    dispatch(loginFailure(errorMessage));
  }
};

// Отзыв токена
type TokenRevocationSuccessType = {
  type: typeof TOKEN_REVOCATION_SUCCESS;
};

export const tokenRevocationSuccess = (): TokenRevocationSuccessType => ({
  type: TOKEN_REVOCATION_SUCCESS,
});

type TokenRevocationFailureType = {
  type: typeof TOKEN_REVOCATION_FAILURE;
  payload: {
    error: string;
  };
};

export const tokenRevocationFailure = (error: string): TokenRevocationFailureType => ({
  type: TOKEN_REVOCATION_FAILURE,
  payload: {
    error
  }
});

export const tokenRevocation = ():
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    await AccountAPI.logout();

    dispatch(tokenRevocationSuccess());
    dispatch(logout());
  } catch (error: any) {
    dispatch(tokenRevocationFailure(error?.message));
  };
};

// Деавторизация
type LogoutType = {
  type: typeof LOGOUT;
};

export const logout = (): LogoutType => {
  localStorage.removeItem("authtoken");
  localStorage.removeItem("refreshToken");

  return { type: LOGOUT };
};

// Обновление токена через refresh_token
type RefreshTokenSuccessType = {
  type: typeof REFRESH_AUTH_TOKEN;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const refreshTokenSuccess = (access_token: string, refresh_token: string): RefreshTokenSuccessType => ({
  type: REFRESH_AUTH_TOKEN,
  payload: {
    access_token,
    refresh_token
  }
});

type RefreshTokenFailureType = {
  type: typeof REFRESH_AUTH_TOKEN_FAILURE;
  payload: {
    error: string;
  };
};

export const refreshTokenFailure = (error: string): RefreshTokenFailureType => ({
  type: REFRESH_AUTH_TOKEN_FAILURE,
  payload: {
    error
  }
});

export const refreshAuthToken = (refreshToken: string | null):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.updateToken(refreshToken);

    asyncLocalStorage
      .setItem("authtoken", JSON.stringify(response?.data?.access_token))
      .then((): void => {
        dispatch(refreshTokenSuccess(response?.data.access_token, response?.data?.refresh_token));
        axios.defaults.headers.common.Authorization = response?.data?.access_token;
      });

    localStorage.setItem("refreshToken", JSON.stringify(response?.data?.refresh_token));

  } catch (error: any) {
    dispatch(refreshTokenFailure(error?.message));
    dispatch(logout());
  }
};

// Логика получения информации об авторизованном пользователе по токену
type FetchUserInfoSuccessType = {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    userInfo: UserInfoType;
  };
};

export const fetchUserInfoSuccess = (userInfo: UserInfoType): FetchUserInfoSuccessType => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: {
    userInfo
  }
});

type FetchUserInfoFailureType = {
  type: typeof FETCH_USER_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchUserInfoFailure = (error: string): FetchUserInfoFailureType => ({
  type: FETCH_USER_INFO_FAILURE,
  payload: {
    error
  }
});

export const fetchUserInfo = ():
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.fetchUserInfo();

    dispatch(fetchUserInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchUserInfoFailure(error.message));
  }
};

type GetCompanyNameType = {
  type: typeof ACCOUNT_ADD_COMPANY_USER_SUCCESS;
  payload: {
    name: string;
    inn: string;
  };
};

type AccountResetPasswordType = {
  type: typeof ACCOUNT_RESET_PASSWORD_STATUS;
  payload: {
    status: number;
  };
};

export const accountResetPasswordSuccess = (status: number): AccountResetPasswordType => ({
  type: ACCOUNT_RESET_PASSWORD_STATUS,
  payload: {
    status,
  },
});

type AccountResetPasswordFailureType = {
  type: typeof ACCOUNT_RESET_PASSWORD_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const accountResetPasswordFailure = (error?: string): AccountResetPasswordFailureType => ({
  type: ACCOUNT_RESET_PASSWORD_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const accountResetPassword = (
  email: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.resetPassword(email);

    dispatch(accountResetPasswordSuccess(response.status));

  } catch (err) {
    dispatch(accountResetPasswordFailure(err.message));
  }
};

type ConfirmNewPasswordType = {
  type: typeof CONFIRM_NEW_PASSWORD_STATUS;
  payload: {
    status: number;
  };
};

export const confirmNewPasswordSuccess = (status: number): ConfirmNewPasswordType => ({
  type: CONFIRM_NEW_PASSWORD_STATUS,
  payload: {
    status,
  },
});

type ConfirmNewPasswordFailureType = {
  type: typeof CONFIRM_NEW_PASSWORD_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const confirmNewPasswordFailure = (error?: string): ConfirmNewPasswordFailureType => ({
  type: CONFIRM_NEW_PASSWORD_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const checkConfirmNewPasswordStatus = (
  resetToken?: string,
):
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.confirmNewPasswordStatus(resetToken);

    dispatch(confirmNewPasswordSuccess(response.status));

  } catch (err) {
    dispatch(confirmNewPasswordFailure(err.message));
  }
};

type GetUsersListSuccessType = {
  type: typeof GET_USERS_LIST_SUCCESS;
  payload: {
    usersList: UserListType;
  };
};

export const getUsersListSuccess = (usersList: UserListType): GetUsersListSuccessType => ({
  type: GET_USERS_LIST_SUCCESS,
  payload: {
    usersList,
  },
});

type GetUsersListFailureType = {
  type: typeof GET_USERS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const getUsersListFailure = (error?: string): GetUsersListFailureType => ({
  type: GET_USERS_LIST_FAILURE,
  payload: {
    error,
  },
});

export const getUsersListInfo = (
  page: number,
  pageSize: number,
  search?: string,
  email?: string,
  role?: string,
  department?: string,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.getUsersList(
      page,
      pageSize,
      search,
      email,
      role,
      department
    );

    dispatch(getUsersListSuccess(response.data));
  } catch (err) {
    dispatch(getUsersListFailure(err.message));
  }
};

type UpdateUserSuccessType = {
  type: typeof UPDATE_USER_SUCCESS;
  payload: {
    userUpdateStatus: number;
  };
};

export const updateUserSuccess = (userUpdateStatus: number): UpdateUserSuccessType => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    userUpdateStatus,
  },
});

type UpdateUserFailureType = {
  type: typeof UPDATE_USER_FAILURE;
  payload: {
    error: string;
  };
};

export const updateUserFailure = (error: string): UpdateUserFailureType => ({
  type: UPDATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const updateUserInfo = (
  id: number,
  name: string,
  email: string,
  password: string,
  departmentId: string | number,
  roleId: string | number,
  isActive: boolean,
  isSuperuser: boolean,
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.updateUser(
      id,
      name,
      email,
      password,
      departmentId,
      roleId,
      isActive,
      isSuperuser,
    );

    dispatch(updateUserSuccess(response.status));
  } catch (err) {
    dispatch(updateUserFailure(err.response.data.detail));
  }
};

type DeleteUserSuccessType = {
  type: typeof DELETE_USER_SUCCESS;
  payload: {
    deleteUserStatus: number;
  };
};

export const deleteUserSuccess = (deleteUserStatus: number): DeleteUserSuccessType => ({
  type: DELETE_USER_SUCCESS,
  payload: {
    deleteUserStatus,
  },
});

type DeleteUserFailureType = {
  type: typeof DELETE_USER_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteUserFailure = (error: string): DeleteUserFailureType => ({
  type: DELETE_USER_FAILURE,
  payload: {
    error,
  },
});

export const deleteUserFromList = (id: number,):
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.deleteUser(id);

    dispatch(deleteUserSuccess(response.status));
  } catch (err) {
    dispatch(deleteUserFailure(err.message));
  }
};

type CreateUserSuccessType = {
  type: typeof CREATE_USER_SUCCESS;
  payload: {
    newUser: UserType;
  };
};

export const createUserSuccess = (newUser: UserType): CreateUserSuccessType => ({
  type: CREATE_USER_SUCCESS,
  payload: {
    newUser,
  },
});

type CreateUserFailureType = {
  type: typeof CREATE_USER_FAILURE;
  payload: {
    error: string;
  };
};

export const createUserFailure = (error?: string): CreateUserFailureType => ({
  type: CREATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const createNewUser = (
  email: number,
  name: string,
  password: string,
  departmentId: string | number,
  roleId: string | number,
  isActive: boolean,
):
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.createUser(
      email,
      name,
      password,
      departmentId,
      roleId,
      isActive,
    );

    dispatch(createUserSuccess(response.data));
  } catch (err) {
    dispatch(createUserFailure(err.response.data.detail));
  }
};

type GetRolePermsSuccessType = {
  type: typeof GET_ROLE_PERMS_SUCCESS;
  payload: {
    perms: RolePermsType;
  };
};

export const getRolePermsSuccess = (perms: RolePermsType): GetRolePermsSuccessType => ({
  type: GET_ROLE_PERMS_SUCCESS,
  payload: {
    perms,
  },
});

type GetRolePermsFailureType = {
  type: typeof GET_ROLE_PERMS_FAILURE;
  payload: {
    error: string;
  };
};

export const getRolePermsFailure = (error: string): GetRolePermsFailureType => ({
  type: GET_ROLE_PERMS_FAILURE,
  payload: {
    error,
  },
});

export const getRolePermsInfo = ():
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.getRolePerms();

    dispatch(getRolePermsSuccess(response.data));
  } catch (err) {
    dispatch(getRolePermsFailure(err.message));
  }
};

type GetRolesSuccessType = {
  type: typeof GET_ROLES_SUCCESS;
  payload: {
    roles: RoleType[];
  };
};

export const getRolesSuccess = (roles: RoleType[]): GetRolesSuccessType => ({
  type: GET_ROLES_SUCCESS,
  payload: {
    roles,
  },
});

type GetRolesFailureType = {
  type: typeof GET_ROLES_FAILURE;
  payload: {
    error: string;
  };
};

export const getRolesFailure = (error: string): GetRolesFailureType => ({
  type: GET_ROLES_FAILURE,
  payload: {
    error,
  },
});

export const getRolesInfo = (name? : string):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getRoles(name);

    dispatch(getRolesSuccess(response.data));
  } catch (err) {
    dispatch(getRolesFailure(err.message));
  }
};

type CreateRoleSuccessType = {
  type: typeof CREATE_ROLE_SUCCESS;
  payload: {
    newRole: RoleType;
  };
};

export const createRoleSuccess = (newRole: RoleType): CreateRoleSuccessType => ({
  type: CREATE_ROLE_SUCCESS,
  payload: {
    newRole,
  },
});

type CreateRoleFailureType = {
  type: typeof CREATE_ROLE_FAILURE;
  payload: {
    error: string;
  };
};

export const createRoleFailure = (error?: string): CreateRoleFailureType => ({
  type: CREATE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const createNewRole = (
name?: string,
permissions?: string[]
):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.createRole(name, permissions);

    dispatch(createRoleSuccess(response.data));
  } catch (err) {
    dispatch(createRoleFailure(err.response.data.detail));
  }
};

type DeleteRoleSuccessType = {
  type: typeof DELETE_ROLE_SUCCESS;
  payload: {
    deleteRoleStatus: number;
  };
};

export const deleteRoleSuccess = (deleteRoleStatus: number): DeleteRoleSuccessType => ({
  type: DELETE_ROLE_SUCCESS,
  payload: {
    deleteRoleStatus,
  },
});

type DeleteRoleFailureType = {
  type: typeof DELETE_ROLE_FAILURE;
  payload: {
    error: string;
  };
};

export const deleteRoleFailure = (error?: string): DeleteRoleFailureType => ({
  type: DELETE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const deleteRoleFromList = (roleId: number):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.deleteRole(roleId);

    dispatch(deleteRoleSuccess(response.status));
  } catch (err) {
    dispatch(deleteRoleFailure(err.response.data.detail));
  }
};

type GetRoleInfoSuccessType = {
  type: typeof GET_ROLE_SUCCESS;
  payload: {
    roleInfo: RoleType;
  };
};

export const getRoleInfoSuccess = (roleInfo: RoleType): GetRoleInfoSuccessType => ({
  type: GET_ROLE_SUCCESS,
  payload: {
    roleInfo,
  },
});

type GetRoleInfoFailureType = {
  type: typeof GET_ROLE_FAILURE;
  payload: {
    error: string;
  };
};

export const getRoleInfoFailure = (error?: string): GetRoleInfoFailureType => ({
  type: GET_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const getRoleInfo = (roleId: number):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getRole(roleId);

    dispatch(getRoleInfoSuccess(response.data));
  } catch (err) {
    dispatch(getRoleInfoFailure(err.message));
  }
};

type UpdateRoleSuccessType = {
  type: typeof UPDATE_ROLE_SUCCESS;
  payload: {
    roleUpdateStatus: number;
  };
};

export const updateRoleSuccess = (roleUpdateStatus: number): UpdateRoleSuccessType => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: {
    roleUpdateStatus,
  },
});

type UpdateRoleFailureType = {
  type: typeof UPDATE_ROLE_FAILURE;
  payload: {
    error: string;
  };
};

export const updateRoleFailure = (error?: string): UpdateRoleFailureType => ({
  type: UPDATE_ROLE_FAILURE,
  payload: {
    error,
  },
});

export const updateRoleInfo = (
roleId: number,
name: string,
permissions: string[]
):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.updateRole(roleId, name, permissions);

    dispatch(updateRoleSuccess(response.status));
  } catch (err) {
    dispatch(updateRoleFailure(err.message));
  }
};

type NewPasswordConfirmedStatusType = {
  type: typeof NEW_PASSWORD_CONFIRMED_STATUS;
  payload: {
    newPasswordConfirmedStatus: number;
  };
};

export const newPasswordConfirmedStatusSuccess = (newPasswordConfirmedStatus: number): NewPasswordConfirmedStatusType => ({
  type: NEW_PASSWORD_CONFIRMED_STATUS,
  payload: {
    newPasswordConfirmedStatus: newPasswordConfirmedStatus,
  },
});

type NewPasswordConfirmedStatusFailureType = {
  type: typeof NEW_PASSWORD_CONFIRMED_STATUS_FAILURE;
  payload: {
    error: string;
  };
};

export const newPasswordConfirmedStatusFailure = (error?: string): NewPasswordConfirmedStatusFailureType => ({
  type: NEW_PASSWORD_CONFIRMED_STATUS_FAILURE,
  payload: {
    error,
  },
});

export const checkNewPasswordConfirmedStatus = (
  resetToken?: string,
  newPassword?: string,
):  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.sendNewPassword(resetToken, newPassword);

    dispatch(newPasswordConfirmedStatusSuccess(response.status));

  } catch (err) {
    dispatch(newPasswordConfirmedStatusFailure(err.message));
  }
};

type SavePortalSettingsSuccessType = {
  type: typeof SAVE_PORTAL_SETTINGS_SUCCESS;
  payload: {
    savePortalSettingsStatus: number;
  };
};

export const savePortalSettingsSuccess = (savePortalSettingsStatus: number)
  : SavePortalSettingsSuccessType => ({
  type: SAVE_PORTAL_SETTINGS_SUCCESS,
  payload: {
    savePortalSettingsStatus: savePortalSettingsStatus,
  },
});

type SavePortalSettingsFailureType = {
  type: typeof SAVE_PORTAL_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const savePortalSettingsFailure = (error?: string)
  : SavePortalSettingsFailureType => ({
  type: SAVE_PORTAL_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const savePortalSettingsData = (
  data: { portalName: string },
  logo: File|UploadFileType,
  favicon: File|UploadFileType,
):  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.savePortalSettings(data, logo, favicon);

    dispatch(savePortalSettingsSuccess(response.status));

  } catch (err) {
    dispatch(savePortalSettingsFailure(err.message));
  }
};

type GetPortalSettingsSuccessType = {
  type: typeof GET_PORTAL_SETTINGS_SUCCESS;
  payload: {
    portalSettings: PortalSettingsDataType;
  };
};

export const getPortalSettingsSuccess = (portalSettings: PortalSettingsDataType)
  : GetPortalSettingsSuccessType => ({
  type: GET_PORTAL_SETTINGS_SUCCESS,
  payload: {
    portalSettings: portalSettings,
  },
});

type GetPortalSettingsStatusType = {
  type: typeof GET_PORTAL_SETTINGS_STATUS_SUCCESS;
  payload: {
    portalSettingsStatus: number;
  };
};

export const getPortalSettingsStatus = (portalSettingsStatus: number)
  : GetPortalSettingsStatusType => ({
  type: GET_PORTAL_SETTINGS_STATUS_SUCCESS,
  payload: {
    portalSettingsStatus: portalSettingsStatus,
  },
});

type GetPortalSettingsFailureType = {
  type: typeof GET_PORTAL_SETTINGS_FAILURE;
  payload: {
    error: string;
  };
};

export const getPortalSettingsFailure = (error?: string)
  : GetPortalSettingsFailureType => ({
  type: GET_PORTAL_SETTINGS_FAILURE,
  payload: {
    error,
  },
});

export const getPortalSettingsData = ():
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getPortalSettings();

    dispatch(getPortalSettingsSuccess(response.data));
    dispatch(getPortalSettingsStatus(response.status));

  } catch (err) {
    dispatch(getPortalSettingsFailure(err.message));
  }
};
